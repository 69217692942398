.flow-view-appear {
  margin-left: 1000px;
}

.flow-view-enter-done {
  margin-left: 0;

  @include media-breakpoint-up(sm) {
    margin-left: 8.3333333333%;
  }
  transition: margin-left 210ms ease-out;

}