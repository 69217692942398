.view-bg {
  max-width: 800px;
  background: #FFF;
  border-radius: 4px;
  box-shadow: 0 .5rem 1rem rgba(0,0,0,.15);
  margin: 0 auto 2rem;
  padding: 2em 1em;
  overflow-x: hidden;

  & > div {
    overflow-x: hidden;
    margin-bottom: 2rem;
  }
}
