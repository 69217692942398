$view-header-font-size: 14px;

.view-header {
  h3 {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 1.5em;
    text-align: center;
  }

  .step-count {
    font-size: $view-header-font-size;
    margin-top: 1rem;
    margin-bottom: 10px;
  }
}
