.page-header {
  h1 {
    margin: 2rem 0;
  }
  img {
    max-width: 100%;
    max-height: 90px;
    width: auto;
  }
}

.main-headline {
  font-weight: 600;
  font-size: 24px;
}
