.modal-spinner {
  display: none;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 999;
  background: rgba(0, 0, 0, 0.65);
  color: #FFF;
  overflow: hidden;
  overscroll-behavior: none;

  .spin-wrap {
    position: relative;
    height: 100%;
    top: 33%;
    text-align: center;
    font-size: 1.5em;
  }

  &.active {
    display: block;
  }
}

.modal {
  p, ul > li {
    font-weight: normal;
  }

  .modal-header {
    min-height: 4em !important;
  }

  button.close {
    cursor: pointer;
    font-weight: 700;
    font-size: 1.75em;
    color: #AAA;
    position: absolute !important;
    background-color: #FFF;
    width: 2em;
    height: 2em;
    box-shadow: none;
    border: 0;
  }

  &.show {
    display: block;
    background-color: rgba(0, 0, 0, 0.65);

    .col-xs-12 {
      @extend .col-12;
    }
  }

  .modal-body {
    padding-top: 1.5em;
  }

  .modal-dialog {
    max-width: 600px;
  }
}

#confirmation-modal-wrapper {
  .modal-dialog {
    top: 15%;

    @media screen and (min-width: 576px) {
      top: 25%;
    }
  }

  .agent-img-wrap {
    text-align: center;

    @media screen and (min-width: 576px) {
      text-align: left;
    }
  }

  .fa-phone {
    margin-right: 0.25em;
  }

  .user-phone-msg {
    $animationDuration: 0.75s;
    $animationTimingFxn: ease-in-out;

    .prd1 {
      animation-duration: $animationDuration;
      animation-delay: 0s;
      display: inline-block;

      animation-name: stretch;
      animation-timing-function: $animationTimingFxn;
      animation-iteration-count: infinite;
      animation-play-state: running;
    }

    .prd2 {
      animation-duration: $animationDuration;
      animation-delay: 0.125s;
      display: inline-block;

      animation-name: stretch;
      animation-timing-function: $animationTimingFxn;
      animation-iteration-count: infinite;
      animation-play-state: running;
    }

    .prd3 {
      animation-duration: $animationDuration;
      animation-delay: 0.25s;
      display: inline-block;

      animation-name: stretch;
      animation-timing-function: $animationTimingFxn;
      animation-iteration-count: infinite;
      animation-play-state: running;
    }

    @keyframes stretch {
      0% {
        transform: translateY(0);
      }

      15% {
        transform: translateY(-0.25em);
      }

      50% {
        transform: translateY(0);
      }

      100% {
        transform: translateY(0);
      }
    }
  }
}

#userFirstName {
  text-transform: capitalize;
}

a[data-toggle="modal"] {
  cursor: pointer;
}