@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600&family=Poppins:wght@300;400;600&display=swap);
@import url(https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css);
@import "../node_modules/bootstrap/scss/bootstrap";

@import "_lib/variables";
@import "_lib/base";
@import "_lib/header";
@import "_lib/site-headline";
@import "_lib/question-bg";
@import "_lib/view-header";
@import "_lib/flow-button";
@import "_lib/input-range";
@import "_lib/forms";
@import "_lib/footer";
@import "_lib/modal";
@import "_lib/page-transitions";
@import "_lib/_pages/start";
@import "_lib/_pages/verify-information";
